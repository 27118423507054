import { Scene } from "phaser";
import Phaser from "phaser";

export default class Vocab1Scene extends Scene {
  constructor() {
    super({ key: "Vocab1Scene" });
  }

  init(data) {
    this.autoplay = data.autoplay;
    this.lang = data.lang;
    this.backScene = data.backScene;
  }

  preload() {
    this.load.image("nose", "/assets/mockups/ABC/extra/images/nose.png");
    this.load.image("mouth", "/assets/mockups/ABC/extra/images/mouth.png");
    this.load.image("ear", "/assets/mockups/ABC/extra/images/ear.png");
    this.load.image("eye", "/assets/mockups/ABC/extra/images/eye.png");
    this.load.image("hand", "/assets/mockups/ABC/extra/images/hand.png");
    this.load.image("leg", "/assets/mockups/ABC/extra/images/leg.png");

    this.load.image(
      "imgCorrectMark",
      "/assets/gameAssets/common/images/mark-correct.png"
    );
    this.load.image(
      "imgWrongMark",
      "/assets/gameAssets/common/images/mark-wrong.png"
    );
  }

  create() {
    this.add.rectangle(240, 427, 480, 854, 0x000000, 0.1);
    this.add.rectangle(240, 940, 480, 854, 0xcccccc);

    this.add
      .text(240, 30, "Drag and Drop", {
        fontFamily: "roboto, sans-serif",
        fontSize: 22,
        color: "#00000",
        wordWrap: { width: 400 },
        align: "center",
      })
      .setOrigin(0.5);

    // Create graphics and draw rectangles for boxes
    this.box1 = this.add.graphics();
    this.box1.fillStyle(0xf1f1f1, 1);
    this.box1.fillRect(60, 80, 150, 150);
    this.box1.type = "eye";
    this.box1.lineStyle(2, 0x000000, 0.5);
    this.box1.strokeRect(60, 80, 150, 150);
    this.box1.bounds = [80, 100, 110, 110];
    this.add
      .text(60 + 75, 80 + 165, "Eye", {
        fontFamily: "roboto, sans-serif",
        fontSize: 22,
        color: "#00000",
        wordWrap: { width: 400 },
        align: "center",
      })
      .setOrigin(0.5);

    this.box2 = this.add.graphics();
    this.box2.fillStyle(0xf1f1f1, 1);
    this.box2.fillRect(270, 80, 150, 150);
    this.box2.type = "mouth";
    this.box2.lineStyle(2, 0x000000, 0.5);
    this.box2.strokeRect(270, 80, 150, 150);
    this.box2.bounds = [290, 100, 110, 110];
    this.add
      .text(270 + 75, 80 + 165, "Mouth", {
        fontFamily: "roboto, sans-serif",
        fontSize: 22,
        color: "#00000",
        wordWrap: { width: 400 },
        align: "center",
      })
      .setOrigin(0.5);

    this.box3 = this.add.graphics();
    this.box3.fillStyle(0xf1f1f1, 1);
    this.box3.fillRect(60, 290, 150, 150);
    this.box3.type = "ear";
    this.box3.lineStyle(2, 0x000000, 0.5);
    this.box3.strokeRect(60, 290, 150, 150);
    this.box3.bounds = [80, 310, 110, 110];
    this.add
      .text(60 + 75, 290 + 165, "Ear", {
        fontFamily: "roboto, sans-serif",
        fontSize: 22,
        color: "#00000",
        wordWrap: { width: 400 },
        align: "center",
      })
      .setOrigin(0.5);

    this.box4 = this.add.graphics();
    this.box4.fillStyle(0xf1f1f1, 1);
    this.box4.fillRect(270, 290, 150, 150);
    this.box4.type = "nose";
    this.box4.lineStyle(2, 0x000000, 0.5);
    this.box4.strokeRect(270, 290, 150, 150);
    this.box4.bounds = [290, 310, 110, 110];
    this.add
      .text(270 + 75, 290 + 165, "Nose", {
        fontFamily: "roboto, sans-serif",
        fontSize: 22,
        color: "#00000",
        wordWrap: { width: 400 },
        align: "center",
      })
      .setOrigin(0.5);

    this.img1 = this.add
      .image(100, 600, "eye")
      .setScale(0.1)
      .setInteractive({ useHandCursor: true, draggable: true });
    this.img1.type = "eye";

    this.img2 = this.add
      .image(240, 600, "hand")
      .setScale(0.1)
      .setInteractive({ useHandCursor: true, draggable: true });
    this.img2.type = "hand";

    this.img3 = this.add
      .image(380, 600, "ear")
      .setScale(0.1)
      .setInteractive({ useHandCursor: true, draggable: true });
    this.img3.type = "ear";

    this.img4 = this.add
      .image(100, 730, "nose")
      .setScale(0.1)
      .setInteractive({ useHandCursor: true, draggable: true });
    this.img4.type = "nose";

    this.img5 = this.add
      .image(240, 730, "leg")
      .setScale(0.1)
      .setInteractive({ useHandCursor: true, draggable: true });
    this.img5.type = "leg";

    this.img6 = this.add
      .image(380, 730, "mouth")
      .setScale(0.1)
      .setInteractive({ useHandCursor: true, draggable: true });
    this.img6.type = "mouth";

    // Drag events
    this.input.on("dragstart", (pointer, gameObject) => {
      gameObject.setScale(0.12);
    });

    this.input.on("drag", (pointer, gameObject, dragX, dragY) => {
      gameObject.x = dragX;
      gameObject.y = dragY;
    });

    this.input.on("dragend", (pointer, gameObject) => {
      this.checkDrop(gameObject);
    });

    this.add
      .graphics()
      .fillStyle(0x2563eb, 1)
      .fillRoundedRect(190, 800, 100, 30, 10);

    this.add
      .text(190, 805, "     Reset      ", {
        fontFamily: "roboto, sans-serif",
        fontSize: 20,
        color: "#ffffff",
        wordWrap: { width: 400 },
        align: "center",
      })
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", () => {
        this.scene.restart();
      });
  }

  checkDrop(item) {
    const boxes = [this.box1, this.box2, this.box3, this.box4];
    // console.log(this.img1.getBounds())

    let fitBox = boxes.find((box) => {
      return (
        Phaser.Geom.Intersects.RectangleToRectangle(
          item.getBounds(),
          new Phaser.Geom.Rectangle(...box.bounds)
        ) && !box.isOccupied
      );
    });

    if (fitBox) {
      fitBox.isOccupied = true;
      item.x = fitBox.bounds[0] + fitBox.bounds[2] / 2;
      item.y = fitBox.bounds[1] + fitBox.bounds[3] / 2;
      item.setScale(0.13);
      item.input.draggable = false;
      if (fitBox.type == item.type) {
        this.add
          .image(item.x + 50, item.y + 50, "imgCorrectMark")
          .setScale(0.5);
      } else {
        this.add.image(item.x + 50, item.y + 50, "imgWrongMark").setScale(0.5);
      }
    } else {
      item.x = item.input.dragStartX;
      item.y = item.input.dragStartY;
      item.setScale(0.1);
    }
  }

  clickClose() {
    //
  }

  clickSetting() {
    //
  }

  update() {}
}
