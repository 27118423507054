import Phaser from "phaser";
import Vocab1Scene from "@/mockups/ABC/extra/scenes/vocab1";

function launch(parent) {
  return new Phaser.Game({
    type: Phaser.AUTO,
    backgroundColor: 0xffffff,
    scale: {
      parent: parent,
      mode: Phaser.Scale.FIT,
      autoCenter: Phaser.Scale.CENTER_BOTH,
      width: 480,
      height: 854,
    },
    physics: {
      default: "arcade",
    },
    plugins: {
      global: [],
    },
    dom: {
      createContainer: true,
    },
    scene: [Vocab1Scene],
  });
}

export default launch;
export { launch };
